import { Link, useParams } from '@remix-run/react';
import { format } from 'date-fns';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import {
  type DtoChannel,
  type DtoChannelProgramLink,
  EnumsProgramRoundStatus,
  EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { type Organization } from '../../types';
import { CalendarIcon } from '../icons/CalendarIcon';
import { EditIcon } from '../icons/EditIcon';
import { TeamIcon } from '../icons/TeamIcon';
import { TimerIcon } from '../icons/TimerIcon';
import { Loading } from '../Loading';
import { useOrgFeatureContext } from '../Organization';
import { castProgramRounds, type IntrosProgramRound } from '../Program';
import { AnalyticsProgramDetailsLayout } from './AnalyticsShared';

function AnalyticsProgramsIntros(props: {
  organization: Organization;
  channel: DtoChannel;
  programLink: DtoChannelProgramLink;
  rounds: IntrosProgramRound[];
}) {
  const { organization, channel, programLink, rounds } = props;
  const { routePrefix } = useOrgFeatureContext();

  const showRounds = useMemo(() => {
    return rounds.filter((r) =>
      [
        EnumsProgramRoundStatus.ProgramRoundStatusInProgress,
        EnumsProgramRoundStatus.ProgramRoundStatusCompleted,
      ].includes(r.status)
    );
  }, [rounds]);
  const introsMade = useMemo(() => {
    return showRounds.reduce((acc, round) => {
      return acc + (round.extensions?.groupsCount || 0);
    }, 0);
  }, [showRounds]);
  const groupsMet = useMemo(() => {
    return showRounds.reduce((acc, round) => {
      return acc + (round.extensions?.completedGroupsCount || 0);
    }, 0);
  }, [showRounds]);
  const groupsMetPercentage = useMemo(() => {
    return groupsMet > 0 ? Math.ceil((groupsMet / introsMade) * 100) : 0;
  }, [groupsMet, introsMade]);

  return (
    <AnalyticsProgramDetailsLayout
      organization={organization}
      programLink={programLink}
      backLink={`${routePrefix}/analytics/programs`}
    >
      <main className='mt-6 w-full'>
        <p className='text-2xl font-medium'>{`#${channel.name}`}</p>
        <div className='mt-2.5 flex items-center gap-5 text-sms text-icon-gray'>
          <div className='flex items-center gap-2'>
            <CalendarIcon className='w-4 h-4 fill-current' />
            <p>{`Every ${pluralize(
              'week',
              programLink.cadenceSettings?.frequency || 1,
              true
            )}`}</p>
          </div>
          <div className='flex items-center gap-2'>
            <TeamIcon className='w-4 h-4 fill-current' />
            <p>Group of {programLink.groupSettings?.size}</p>
          </div>
          <div className='flex items-center gap-2'>
            <EditIcon className='w-4 h-4 fill-current' />
            <p>
              Created on {format(new Date(programLink.createdAt), 'd MMM yyyy')}
            </p>
          </div>
          <div className='flex items-center gap-2'>
            <TimerIcon className='w-4 h-4 fill-current' />
            <p>
              Next Intro:{' '}
              {format(
                new Date(programLink.cadenceSettings?.nextTriggerTime ?? ''),
                'd MMM yyyy'
              )}
            </p>
          </div>
        </div>
        <div className='mt-5 w-full h-40 flex items-center gap-2.5'>
          <div
            className='
              w-1/3 h-full bg-layer-001 rounded-2.5xl flex flex-col justify-center items-center gap-2.5
              text-blue-005
            '
          >
            <p className='text-4xl font-bold'>{introsMade}</p>
            <p className='font-bold'>{pluralize('Intro', introsMade)} made</p>
          </div>
          <div
            className='
              w-1/3 h-full bg-layer-001 rounded-2.5xl flex flex-col justify-center items-center gap-2.5
            text-tertiary
            '
          >
            <p className='text-4xl font-bold'>{groupsMet}</p>
            <p className='font-bold'>{pluralize('Group', groupsMet)} met</p>
          </div>
          <div
            className='
              w-1/3 h-full bg-layer-001 rounded-2.5xl flex flex-col justify-center items-center gap-2.5
              text-pink-001
            '
          >
            <p className='text-4xl font-bold'>{groupsMetPercentage}%</p>
            <p className='font-bold'>of groups met</p>
          </div>
        </div>
        <div className='mt-7.5'>
          <div className='font-bold'>Stats per Round</div>
          <div className='mt-2.5 text-xs text-[#8B8B8B]'>
            Click on the intro dates to see more details
          </div>

          <table className='mt-6 w-full'>
            <thead>
              <tr className='text-left text-sms text-icon-gray border-b border-secondary'>
                <th>Intro Date</th>
                <th>Intros Made</th>
                <th>Groups Met</th>
                <th>% Met</th>
              </tr>
            </thead>
            <tbody>
              {showRounds.map((round) => (
                <tr key={round.id} className='h-12'>
                  <td>
                    <Link
                      to={`${routePrefix}/analytics/programs/${programLink.id}/rounds/${round.id}`}
                      className='underline'
                    >
                      {format(
                        new Date(round.startedAt || ''),
                        'EEE, d MMM yyyy'
                      )}
                    </Link>
                  </td>
                  <td>{round.extensions?.groupsCount || 0}</td>
                  <td>{round.extensions?.completedGroupsCount || 0}</td>
                  <td>
                    {round.extensions?.groupsCount
                      ? Math.ceil(
                          (round.extensions.completedGroupsCount /
                            round.extensions.groupsCount) *
                            100
                        )
                      : 0}
                    %
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {showRounds.length === 0 && (
            <div className='mt-4 w-full text-center text-sms text-secondary'>
              No intros yet!
            </div>
          )}
        </div>
      </main>
    </AnalyticsProgramDetailsLayout>
  );
}

async function loadData(programLinkId: string) {
  const [channel, rounds] = await Promise.all([
    (
      await apiService.channel.getChannelByProgramLinkId(programLinkId)
    ).data.channel,
    (await apiService.program.queryRounds(programLinkId)).data.programRounds,
  ]);
  const programLink = channel.programLinks.find(
    (link) => link.id === programLinkId
  );
  if (!programLink) throw new Error('program link not found');

  return {
    channel,
    rounds,
    programLink,
  };
}

export function AnalyticsProgramDetails(props: { organization: Organization }) {
  const { programLinkId = '' } = useParams<'programLinkId'>();
  const { data, isLoading } = useSWRImmutable(
    [`/analytics/programs/${programLinkId}`],
    async () => loadData(programLinkId)
  );

  if (isLoading || !data)
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <Loading />
      </div>
    );
  const { channel, programLink, rounds } = data;

  switch (programLink.programType) {
    case EnumsProgramType.ProgramTypeIntros:
      return (
        <AnalyticsProgramsIntros
          organization={props.organization}
          channel={channel}
          programLink={programLink}
          rounds={castProgramRounds<IntrosProgramRound>(rounds)}
        />
      );
    default:
      return <div>Unknown program type</div>;
  }
}
